const colors = {
  blue: "#33B9FF",
  quietBlue: "rgba(56, 71, 104, 1)",
  gray: "#2d354b",
  lightGray: "#F6FBFE",
  white: "#ffffff",
  orange: "#F26722",
  error: "#ff0100",
  lightText: "#737B92",
  lightGreen: "#19cea652",
  beige: "#EBE7E1",
  grey: "#64787A",
  black: "#212736",
  green: "#19CEA6",
  Green: "#19CEA6",
};

module.exports = {
  grid: {
    maxWidth: "1290px",
    horizontalPadding: "20px",
  },

  breakpoints: {
    sm: "0px",
    md: "568px",
    lg: "1024px",
    xl: "1290px",
  },

  fonts: {
    primary: "Raleway, sans-serif",
    secondary: "Work Sans, sans-serif",
    third:"Open Sans, sans-serif",
  },

  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },

  colors: {
    ...colors,
    input: {
      label: {
        color: colors.gray,
      },
      color: colors.gray,
    },
    button: {
      primary: {
        background: colors.blue,
        text: colors.white,
      },
      secondary: {
        background: colors.green,
        text: colors.white,
      },
    },
    header: {
      background: colors.white,
      text: {
        color: {
          normal: colors.grey,
          active: colors.black,
        },
      },
      icons: {
        color: colors.white,
      },
    },
    footer: {
      background: colors.blue,
      text: colors.white,
      icons: {
        color: colors.white,
      },
    },
    section: {
      dark: {
        background: colors.black,
        text: colors.white,
      },
      intermediate: {
        background: colors.lightGray,
        text: colors.gray,
      },
      light: {
        background: colors.white,
        text: colors.gray,
      },
    },
  },
  icons: {
    size: 16,
  },
};
