import React from "react";
import styled, { css } from "styled-components";
import { Section } from "react-scroll-section";
import PropTypes from "prop-types";
import Reveal from "react-reveal/Reveal";

const LightSectionText = css`
  color: ${({ theme }) => theme.colors.section.light.text};
`;

const DarkSectionText = css`
  color: ${({ theme }) => theme.colors.section.dark.text};
`;

const IntermediateSectionText = css`
  color: ${({ theme }) => theme.colors.section.intermediate.text};
`;

const LightSectionBackground = css`
  background: ${({ theme }) => theme.colors.section.light.background};
`;

const DarkSectionBackground = css`
  background: ${({ theme }) => theme.colors.section.dark.background};
`;

const IntermediateSectionBackground = css`
  background: ${({ theme }) => theme.colors.section.intermediate.background};
`;

const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-align: ${(props) => props.textAlign};
  font-size: 28px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0;
  position: relative;
  z-index: 1;

  ${({ variant }) => variant === "dark" && DarkSectionText};
  ${({ variant }) => variant === "light" && LightSectionText};
  ${({ variant }) => variant === "intermediate" && IntermediateSectionText};
`;

const DarkSection = css`
  ${DarkSectionBackground};
  ${DarkSectionText};
`;

const LightSection = css`
  ${LightSectionBackground};
  ${LightSectionText};
`;

const IntermediateSection = css`
  ${IntermediateSectionBackground};
  ${IntermediateSectionText};
`;

const SectionContainer = styled.div`
  ${({ noPaddingTop }) => (noPaddingTop ? "padding-top: 0 !important;" : null)}
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  scroll-behavior: smooth;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: ${(props) =>
    props.invertColors ? props.theme.colors.white : props.theme.colors.b};
  padding: ${(props) => (props.disableVerticalMargins ? 0 : "65px 0px")};

  ${({ variant }) => variant === "dark" && DarkSection};
  ${({ variant }) => variant === "light" && LightSection};
  ${({ variant }) => variant === "intermediate" && IntermediateSection};
`;

const Description = styled.h3`
  display: flex;
  align-items: center;
  max-width: 570px;
  margin: 0 !important;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;

  ${SectionTitle}, ${Description} {
    margin-top: ${(props) =>
      props.needsMarginTop ? (props.smallMarginBottom ? "18px" : "23px") : "0"};
    margin-bottom: ${(props) => (props.smallMarginBottom ? "18px" : "23px")};
  }
`;

const DefaultBackground = () => <div />;

const Container = ({
  id,
  children,
  Background = DefaultBackground,
  invertColors,
  disableVerticalMargins = false,
  variant = "light",
  noPaddingTop = false,
}) => {
  return (
    <Section id={id} style={{ position: "relative" }}>
      <Background />
      <SectionContainer
        invertColors={invertColors}
        disableVerticalMargins={disableVerticalMargins}
        variant={variant}
        noPaddingTop={noPaddingTop}
      >
        {children}
      </SectionContainer>
    </Section>
  );
};

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Background: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  invertColors: PropTypes.bool,
  variant: PropTypes.string,
};

const Header = ({
  name,
  description = "",
  textAlign = "center",
  invertColors,
  titleColor,
  needsMarginTop,
  variant = "light",
}) => (
  <>
    <HeaderContainer
      smallMarginBottom={description}
      needsMarginTop={needsMarginTop}
      invertColors={invertColors}
    >
      <SectionTitle color={titleColor} textAlign={textAlign} variant={variant}>
        {name}
      </SectionTitle>

      {description ? (
        <Reveal>
          <Description variant={variant}>{description}</Description>
        </Reveal>
      ) : null}
    </HeaderContainer>
  </>
);

Header.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default {
  Container,
  Header,
};
